import React from 'react'
import './Reasons.css'
import image_1 from "../../assets/image1.png";
import image_2 from "../../assets/image2.png";
import image_3 from "../../assets/image3.png";
import image_4 from "../../assets/image4.png";
import tick_image from '../../assets/tick.png'
import adidas_logo_image from '../../assets/adidas.png'
import nike_logo_image from '../../assets/nike.png'
import nb_logo_image from '../../assets/nb.png'


const Reason = () => {
  return (
    <div className="Reasons" id="Reasons">
      <div className='left-r'>
        <img src={image_1} alt="" />
        <img src={image_2} alt="" />
        <img src={image_3} alt="" />
        <img src={image_4} alt="" />
      </div>

    
      <div className='right-r'>
        <span>some reasons</span>
        <div>
          <span className='stroke-text'>Why</span>
          <span>choose us?</span>
        </div>

        <div className='details-r'>
          <div><img src={tick_image} alt="" />
          <span>OVER 140+ EXPERT COACHS</span>
        </div>
          <div><img src={tick_image} alt="" />
          <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
        </div>
          <div><img src={tick_image} alt="" />
          <span>1 FREE PROGRAM FOR NEW MEMBER</span>
        </div>
          <div><img src={tick_image} alt="" />
          <span>RELIABLE PARTNERS</span>
        </div>
        </div>
        <span>OUR PARTNERS</span>
        <div className='partners'>
          <img src={nb_logo_image} alt="" />
          <img src={nike_logo_image} alt="" />
          <img src={adidas_logo_image} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Reason
