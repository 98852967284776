import React from 'react'
import './Footer.css'
import instagram_image from "../../assets/instagram.png";
import linkedin_image from "../../assets/linkedin.png";
import github_image from "../../assets/github.png";
import logo_image from '../../assets/logo.png'


const Footer = () => {
  return (
    <div className='Footer-container'>
      <hr />
      <div className="footer">
        <div className='social-links'>
          <img src={github_image} alt="" />
          <img src={linkedin_image} alt="" />
          <img src={instagram_image} alt="" />

        </div>
        <div className='logo-f'>
          <img src={logo_image} alt="" />
        </div>
      </div>
      <div class="blur blur-f"></div>
      <div class="blur blur-f"></div>
    </div>
  )
}

export default Footer
