import React from 'react'
import { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_eht8rvy', 'template_l4552l4', form.current, '5IUTxH5H466waDj1I')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="Join" id="join-us">
        <div className='left-j'>
            <hr />
            <div><span className='stroke-text'>Ready to</span><span>Level up</span></div>
            <div><span >your body</span><span className='stroke-text'>with us?</span></div>
        </div>

        <div className='right-j'>
            <form ref={form} onSubmit={sendEmail} class="email-container">
                {/* <input type="text" /> */}
                <input type="email" name="user_email" placeholder="Enter your Email Address here..."/>
                <button type="submit" class="btn btn-j">Join now</button>
            </form>
        </div>
    </div>
  )
}

export default Join
