import React from 'react';
import Header from '../Header/Header';
import './Hero.css';

import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import heart_image from "../../assets/heart.png";
import calories_image from "../../assets/calories.png";
import NumberCounter from 'number-counter'
import { motion } from 'framer-motion';
const Hero = () =>{ 
  const transition = {type: 'spring', duration: 3}
  return (
  <div className='hero' id='page_home'>
    <div className="blur hero-blur"></div>
    <div className="left-h">
      <Header />
      {/*The best ad*/}
      <div className="the-best-ad">
        <motion.div
          initial={{left: '238px'}}
          whileInView={{left: '8px'}}
          transition={{...transition, type: 'tween'}}
        ></motion.div>
        <span>the best fitness club in the town</span>
      </div>

      {/*Hero Heading*/}
      <div className="hero-text">
        <div>
          <span className='stroke-text'>Shape </span>
          <span>Your</span>
        </div>
        <div>
          <span>Ideal Body</span>
        </div>
        <div>
          <span>
            In here we will help you to shape and build your ideal body and live up your life to fullest
          </span>
        </div>
      </div>

      {/*Figures*/}
      <div className="figures">
        <div>
          <span><NumberCounter end={140} start={100} delay='4' preFix='+'/></span>
          <span>EXPERT COACHES</span>
          </div>
        <div>
          <span><NumberCounter end={978} start={700} delay='4' preFix='+' /></span>
          <span>MEMBERS JOINED</span>
          </div>
        <div>
          <span><NumberCounter end={50} start={30} delay='4' preFix='+'/></span>
          <span>FITNESS PROGRAMS</span>
        </div>
      </div>
      {/*Hero buttons*/}
      <div className="hero-button">
        <button className='btn'>Get Started</button>
        <button className='btn'>Learn More</button>
      </div>
    </div> 


    <div className="right-h">      
      <button class="btn"><a>Join Now</a></button>

      <motion.div className="heart-rate"
        initial={{right: "-1rem"}}
        whileInView={{right: '4rem'}}
        transition={transition}
      >
        <img src={heart_image} alt="" />
        <span>Heart Rate</span>
        <span>116 bpm</span>
      </motion.div>

      {/* hero images*/}
      <img src={hero_image} alt="" className="hero-image"/>
      <motion.img 
          initial={{right: "11rem"}}
          whileInView={{right: '20rem'}}
          transition={transition}
      src={hero_image_back} alt="" className="hero-image-back"/>

      {/* calories*/}
      <motion.div className="calories"
        initial={{right: "37rem"}}
        whileInView={{right: '28rem'}}
        transition={transition}
      >
        <img src={calories_image} alt="" />
        <div>
          <span>Calories burned</span>
          <span>220 kcal</span>
        </div>        
      </motion.div>
    </div>
  </div>
)}

export default Hero;
